import React from 'react';
import { Card, Space, Button } from 'antd';
import './index.css';

const MyCard = () => {
    return(
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div style={{position: 'absolute', top: '20%'}}>
                <Space direction="vertical" size={16}>
                    <Card
                    title="ryaber24.us"
                    style={{backgroundColor: '#daeaf6', width: 400}}
                    >
                    <p style={{ display: 'flex', justifyContent: 'center'}}>
                        When working on my own projects, I often use other people's documentation of their own projects to help me. This is my attempt to document my projects, hoping to help others.
                    </p>
                    <p style={{ display: 'flex', justifyContent: 'center'}}>
                        I'm a freshman at RPI in Troy, NY, from near Boston, MA. I'm studying Computer and Systems Engineering.
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <a href='/feed'><Button type="dashed" style={{background: "#d1cfe2"}}>Go to project feed</Button></a>
                    </div>
                    </Card>
                </Space>
            </div>
        </div>
    );
};

export default MyCard;